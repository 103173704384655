import Vue from 'vue'
import VueRouter from 'vue-router'
import LayOut from '../views/layOut.vue'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layOut',
    redirect: '/home',
    component: LayOut,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/programme',
        name: 'programme',
        component: ()=> import ('@/views/programme.vue')
      },
      {
        path: '/product',
        name: 'product',
        component: ()=> import ('@/views/product.vue')
      },
      {
        path: '/case',
        name: 'case',
        component: ()=> import ('@/views/case.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: ()=> import ('@/views/news.vue')
      },
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: ()=> import ('@/views/newsDetail.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: ()=> import ('@/views/about.vue')
      },
      {
        path: '/productDetail',
        name: 'productDetail',
        component: ()=> import ('@/views/productDetail.vue')
      },
      {
        path: '/help',
        name: 'help',
        component: ()=> import ('@/views/help.vue')
      },
    ]
  },

]

const router = new VueRouter({
  routes,
})
//导航冗余
//push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
//replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  // 每次路由切换前滚动页面到顶部
  setTimeout(() => {
    window.scrollTo(0, 0); // 将页面滚动到顶部
  }, 50);
  next();
});
export default router
