<template>
  <div id="app" class="home_box" :style="style">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      rate: 1, // 用于判断整体缩小比例是多少
      style: null,
    }
  },
  mounted() {
    this.resize_window()
    window.addEventListener('resize', () => {
      this.resize_window()
    })
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.resize_window()
    })
  },
  methods: {
    resize_window() {
      const widthRatio = Number(document.documentElement.clientWidth / 1920)
      console.log('widthRatio',widthRatio);
      const height = Number(document.documentElement.clientHeight)
      this.rate = widthRatio
      this.style = {
        'height': (height) + 'px',
        transformOrigin: 'left top',
        transform: `scale(${this.rate},${this.rate})`,
        '-webkit-transform': `scale(${this.rate},${this.rate})`,
        '-moz-transform': `scale(${this.rate},${this.rate})`,
        '-o-transform': `scale(${this.rate},${this.rate})`,
        '-ms-transform': `scale(${this.rate},${this.rate})`,
      }
    },

  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// ::deep .scale-box {  
//     /* 其他样式 */  
//     overflow: hidden;  
// }
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
