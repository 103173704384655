import axios from '@/utils/axios'
import qs from 'qs'
const api = {

  index_banner(){
    return axios.post("/index/banner")
  },
  index_config(){
    return axios.post("/index/config")
  },
  //产品分类
  product_category(params){
    return axios.post('/product/category',qs.stringify(params))
  },
  product_list(params){
    return axios.post('/product/list',qs.stringify(params))
  },
  //案例列表
  case_list(params){
    return axios.post('/case/list',qs.stringify(params))
  },
  //新闻列表
  news_list(params){
    return axios.post('/news/list',qs.stringify(params))
  },
   //新闻详情
   news_info(params){
    return axios.post('/news/info',qs.stringify(params))
  },
  //关于我们详情
  about_info(params){
    return axios.post('/about/info',qs.stringify(params))
  },
  //产品列表
  product_list(params){
    return axios.post('/product/list',qs.stringify(params))
  },
  //产品详情
  product_info(params){
    return axios.post('/product/info',qs.stringify(params))
  },
  //帮助与支持菜单
  help_menu(params){
    return axios.post('/help/menu',qs.stringify(params))
  },
  //帮助列表
  help_list(params){
    return axios.post('/help/list',qs.stringify(params))
  },
  //帮助闲情
  help_info(params){
    return axios.post('/help/info',qs.stringify(params))
  }
}
export default api