<template>
  <div class="container">
    <el-carousel :interval="3500">
      <el-carousel-item v-for="item in imgs" :key="item.id">
        <el-image class="imgsItem" :src="item.img" fit="fill"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="h1">智慧酒店解决方案</div>
    <div class="h2">方便<span></span>效率<span></span>健康<span></span>安全</div>
    <div class="home_one">
      <el-image style="width:1224px;" :src="require('@/assets/Images/home_3.png')" fit="fill"></el-image>
    </div>
    <div class="zhihui">
      <div class="item">
        <el-image class="itemImg" :src="require('@/assets/Images/zhihui_front.png')" fit="fill"></el-image>
        <div class="content">
          <h3>智慧前台</h3>
          <p>单屏自助机、双屏自助机</p>
          <div class="box_button" style=" display: flex;justify-content: center;">
            <div class="button" @click="toPage('product',9)" style="cursor: pointer;">
              <el-image class="buttonImg" :src="require('@/assets/Images/home_look.png')" fit="fill"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <el-image class="itemImg" :src="require('@/assets/Images/zhihui_home.png')" fit="fill"></el-image>
        <div class="content">
          <h3>智慧客房</h3>
          <p>人脸机5寸7寸8寸、</p>
          <p>智能锁、开关面板</p>
          <div class="box_button" style=" display: flex;justify-content: center;">
            <div class="button"  @click="toPage('product',6)" style="cursor: pointer;">
              <el-image class="buttonImg" :src="require('@/assets/Images/home_look.png')" fit="fill"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <el-image class="itemImg" :src="require('@/assets/Images/zhihui_mag.png')" fit="fill"></el-image>
        <div class="content">
          <h3>智慧管理</h3>
          <p>云 PMS、预定小程序</p>
          <div class="box_button" style=" display: flex;justify-content: center;">
            <div class="button"  @click="toPage('programme',99)" style="cursor: pointer;">
              <el-image class="buttonImg" :src="require('@/assets/Images/home_look.png')" fit="fill"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-image style="width: 1920px" :src="require('@/assets/Images/moDao/home_3.png')" fit="fill"></el-image>
    </div>
    <!-- <div class="h1">多项黑科技应用</div>
    <div class="h2">无人工前台&nbsp;&nbsp;&nbsp;全场景刷脸</div> -->
    <div style="margin:130px 0 120px 0">
      <el-image style="width: 1244px;" class="home_4" :src="require('@/assets/Images/moDao/home_4.png')" fit="fill"></el-image>
    </div>
    <div class="clickImg">
      <el-image class="img" :src="require('@/assets/Images/moDao/home_5.png')" fit="fill"></el-image>
      <div @click="toPage('about')" class="clickLook"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [],
    }
  },
  mounted() {
    this.getImages()
  },
  methods: {
    getImages() {
      this.$api.index_banner().then(res => {
        if (res.data.status) {
          this.imgs = res.data.data
        }
      })
    },
    toPage(url,id) {
      this.$router.replace({ name: url, params: { type:id } })
    },
  }
}
</script>

<style lang="scss" scoped>
.h1 {
  height: 47px;
  line-height: 47px;
  color: rgba(16, 16, 16, 1);
  font-size: 32px;
  margin-top: 67px;
  margin-bottom: 32px;
}
.h2 {
  height: 34px;
  line-height: 34px;
  color: rgba(16, 16, 16, 1);
  font-size: 23px;
  margin-bottom: 68px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    width: NaNpx;
    height: 23px;
    border: 1px solid rgba(187, 187, 187, 1);
    margin: 0 15px;
  }
}

.clickImg {
  position: relative;

  .img {
    width: 1920px;
    height: 600px
  }

  .clickLook {
    position: absolute;
    bottom: 185px;
    left: calc(50% - 140px);
    width: 300px;
    height: 63px;
    // border: 1px solid red;
    cursor: pointer;
  }
}

.zhihui {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 114px;
  .item {
    position: relative;
    width: 358px;
    height: 616px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    box-shadow: 0px 2px 18px -2px rgba(187, 212, 255, 1);
    margin-left: 37px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-top: 20px;

    .itemImg {
      width: 358px;
      height: 283px
    }

    .content {
      h3 {
        height: 44px;
        line-height: 44px;
        color: rgba(98, 96, 96, 1);
        font-size: 30px;
        text-align: center;
        margin-top: 23px;
        margin-bottom: 17px;
      }

      p {
        height: 29px;
        color: rgba(152, 151, 151, 1);
        font-size: 20px;
      }

      .box_button {
        position: absolute;
        bottom: 79px;
        left: 68px;
      }

      .button {
        width: 222px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        border-radius: 50px;
        background-color: rgba(0, 90, 247, 1);
        text-align: center;

        .buttonImg {
          width: 128px;
          height: 36px
        }
      }
    }
  }

  @media screen and (max-width:600px) {
    .item {
      position: relative;
      background-color: red;
      width: 768px;
      height: 1224px;
      background-color: rgba(255, 255, 255, 1);
      text-align: center;
      box-shadow: 0px 2px 18px -2px rgba(187, 212, 255, 1);
      margin-left: 74px;
      box-sizing: border-box;
      margin-bottom: 16px;
      margin-top: 40px;

      .itemImg {
        width: 768px;
        height: 572px
      }

      .content {
        h3 {
          height: 88px;
          line-height: 88px;
          color: rgba(98, 96, 96, 1);
          font-size: 60px;
          text-align: center;
          margin-top: 46px;
          margin-bottom: 34px;
        }

        p {
          height: 60px;
          color: rgba(152, 151, 151, 1);
          font-size: 40px;
        }

        .box_button {
          position: absolute;
          bottom: 158px;
          left: 136px;
        }

        .button {
          width: 444px;
          height: 126px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 40px;
          border-radius: 100px;
          background-color: rgba(0, 90, 247, 1);
          text-align: center;

          .buttonImg {
            width: 256px;
            height: 72px
          }
        }
      }
    }
  }
}


::v-deep .el-carousel {
  .el-carousel__container {
    height: 600px;

    .imgsItem {
      height: 600px;
      width: 1920px;
    }
  }
}
</style>